





















 import { Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
Vue.use(GridPlugin);
import { L10n} from '@syncfusion/ej2-base';
L10n.load({
    'en-US': {
        'grid': {
            ConfirmDelete:"Are you sure to cancel this class and delete this record?" ,
            OKButton:"Yes",
            CancelButton:"No",
            Delete:"Cancel Class",
        }
      }
});
@Component({
     provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar]
  }

})
export default class LiveClass extends Vue {
     dataManager = MyGridOption.getDataManager('TeacherLiveClassList');
    filterOptions = {    type: 'Excel'};
    wrapSettings= { wrapMode: 'Both ' };
    toolbar= [  'Delete'];
     editSettings= { allowAdding: false, allowDeleting: true,showDeleteConfirmDialog:true};
      actionFailure(args:any) {
      this.$store.commit("ChangeLoading", false);
      var t = args.error[0].error.response;
      console.log(t);
       this.$bus.$emit('testing_call',{data:t});
}
}
